import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { formActions } from '_store';

const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    phoneNumber: Yup.string().required('Mobile Number is required'),
    age: Yup.string().required('Your Age is required'),
    goal: Yup.string().required('Your Goal is required')
});

function Form() {
    const { register, handleSubmit, formState, reset } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { errors, isSubmitting } = formState;

    const dispatch = useDispatch();
    const errorMessage = useSelector(state => state.form.error);
    const successMessage = useSelector(state => state.form.success);

    const onSubmit = (data) => {
        return dispatch(formActions.submitForm({ data }))
            .then((response) => {
                // Clear the form here
                reset();
                return response;
            })
            .catch((error) => {
                console.error("There was an error submitting the form:", error);
            });
    };

    return (
        <div className="container p-4">
            <form className="bg-gray-800 lg:w-[450px] max-w-lg mx-auto bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>
                <h1 className="font-orbitron text-4xl text-white font-bold text-center m-4 uppercase">You Ready?</h1>

                {/* Full Name Field */}
                <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="fullName">Name *</label>
                    <input
                        type="text"
                        {...register('fullName')}
                        className={`shadow border rounded w-full py-2 px-3 ${errors.fullName ? 'border-red-500' : ''}`}
                        placeholder="Name"
                    />
                    <span className={`text-red-500 h-5 ${errors.fullName ? 'opacity-100' : 'opacity-0'}`}>{errors.fullName ? errors.fullName.message : '\u00A0'}</span>
                </div>

                {/* Email Field */}
                <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="email">Email *</label>
                    <input
                        type="email"
                        {...register('email')}
                        className={`shadow border rounded w-full py-2 px-3 ${errors.email ? 'border-red-500' : ''}`}
                        placeholder="Email Address"
                    />
                    <span className={`text-red-500 h-5 ${errors.email ? 'opacity-100' : 'opacity-0'}`}>{errors.email ? errors.email.message : '\u00A0'}</span>
                </div>

                {/* Phone Number Field */}
                <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="phoneNumber">Mobile Number *</label>
                    <input
                        type="text"
                        {...register('phoneNumber')}
                        className={`shadow border rounded w-full py-2 px-3 ${errors.phoneNumber ? 'border-red-500' : ''}`}
                        placeholder="Mobile Number"
                    />
                    <span className={`text-red-500 h-5 ${errors.phoneNumber ? 'opacity-100' : 'opacity-0'}`}>{errors.phoneNumber ? errors.phoneNumber.message : '\u00A0'}</span>
                </div>

                {/* Age Dropdown */}
                <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="age">Your Age *</label>
                    <select
                        {...register('age')}
                        className={`shadow border rounded w-full py-2 px-3 ${errors.age ? 'border-red-500' : ''}`}
                    >
                        <option value="">Select Age Range</option>
                        <option value="19 or under">19 or under</option>
                        <option value="20 - 24">20 - 24</option>
                        <option value="25 - 39">25 - 39</option>
                        <option value="40 - 55">40 - 55</option>
                        <option value="56 and over">56 and over</option>
                    </select>
                    <span className={`text-red-500 h-5 ${errors.age ? 'opacity-100' : 'opacity-0'}`}>{errors.age ? errors.age.message : '\u00A0'}</span>
                </div>

                {/* Goal Dropdown */}
                <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2" htmlFor="goal">Your Goal *</label>
                    <select
                        {...register('goal')}
                        className={`shadow border rounded w-full py-2 px-3 ${errors.goal ? 'border-red-500' : ''}`}
                    >
                        <option value="">Select Goal</option>
                        <option value="GAIN MUSCLE - and LOTS">GAIN MUSCLE - and LOTS</option>
                        <option value="LOSE FAT - and FAST">LOSE FAT - and FAST</option>
                        <option value="BOTH - Just give me EVERYTHING">BOTH - Just give me EVERYTHING</option>
                    </select>
                    <span className={`text-red-500 h-5 ${errors.goal ? 'opacity-100' : 'opacity-0'}`}>{errors.goal ? errors.goal.message : '\u00A0'}</span>
                </div>

                {/* Submit Button */}
                <div className="flex items-center justify-center">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
                {successMessage && <p className="text-green-500 mt-2">{successMessage}</p>}
                {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
            </form>
        </div>
    );
}

export default Form;


