import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

// Slice name
const name = 'form';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers: {}, extraReducers });

// Exports
export const formActions = { ...slice.actions, ...extraActions };
export const formReducer = slice.reducer;

// Implementation

function createInitialState() {
    return {
        data: {},
        error: null,
        success: null,
        loading: false,
    };
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/text-mail`;

    return {
        submitForm: submitForm()
    };

    function submitForm() {
        return createAsyncThunk(
            `${name}/submitForm`,
            async ({ data }) => { await fetchWrapper.post(baseUrl, data) }
        );
    }
}

function createExtraReducers() {
    return {
        ...handleFormSubmission()
    };

    function handleFormSubmission() {
        var { pending, fulfilled, rejected } = extraActions.submitForm;
        return {
            [pending]: (state) => {
                state.loading = true;
                state.error = null;
                state.success = null;
            },
            [fulfilled]: (state) => {
                state.loading = false;
                state.success = 'Form submitted successfully!';
            },
            [rejected]: (state) => {
                state.loading = false;
                state.error = 'An error occurred. Please try again later.';
            }
        };
    }
}
