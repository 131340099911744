import logo from './logo.png';
import instagramLogo from './instagram-logo.png';
import './index.css';
import TextReveal from './TextReveal';
import Cards from './Cards';
import Form from './Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/free-solid-svg-icons'

export { FrontPage };
    
function FrontPage() {
    return (
        <div className="bg-gradient-to-r from-black to-gray-800 flex flex-col min-h-screen">
            <div className="flex flex-col justify-between">
                <img src={logo} alt="Logo" className="mx-auto max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl" />
                <div>
                    {/* <h1 className="text-gray-300 text-4xl font-bold text-white text-center m-4">Empower Your Body and Mind with </h1>
                    <h1 className="text-gray-300 text-4xl font-bold text-white text-center m-4"><TextReveal text="*Nakamura^ *Strength^" /></h1> */}
                    <h1 className="text-gray-300 text-4xl font-bold text-white text-center m-4">12 WEEK TRANSFORMATION PROGRAM</h1>
                </div>
                {/* <div className="flex justify-center">
                    <p className='text-3xl text-white max-w-lg m-4 text-center italic'>It's Time to Ignite the Flame!&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon
                            icon={faFire}
                            className='text-3xl mr-4'
                            style={{ color: "#e01b24", }}
                            beatFade
                        /></p>
                </div> */}
            </div>

            <div className="flex flex-col lg:flex-row items-center justify-center gap-8">
                <div className="flex flex-col justify-center">
                    <Cards />
                </div>
                <div className="flex justify-center lg:justify-end">
                    <Form />
                </div>
            </div>


            <footer className="bg-gray-900 text-white p-4 flex items-center justify-center mt-auto">
                <a
                    href="https://www.instagram.com/nakamurastrength"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                >
                    <img src={instagramLogo} alt="Instagram" className="h-6 w-6 mr-2" />
                    <span>Follow us on Instagram</span>
                </a>
            </footer>
        </div>

    );
}
