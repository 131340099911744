import React, { useEffect } from 'react';

function Card({ children }) {
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('slide-in');
                    } else {
                        entry.target.classList.remove('slide-in');
                    }
                });
            },
            { threshold: 0.4 } // Adjust the threshold as needed
        );

        const cards = document.querySelectorAll('.card');
        cards.forEach((card) => observer.observe(card));

        return () => observer.disconnect();
    }, []);

    return <div className="card">{children}</div>;
}

function Cards() {
    return (
        <div className="flex flex-col justify-center">
            <Card>
                <div className="p-6 bg-gradient-to-r from-orange-500 to-orange-400 rounded-lg shadow-md text-white max-w-lg m-4 text-center leading-7">
                    <div className="flex items-center justify-center">
                        <p>Your dream body won't be created by accident, but with a proven method to shred fat and gain muscle, it's never been easier.</p>
                    </div>
                </div>
            </Card>
            <Card>
                <div className="p-6 bg-gradient-to-r from-orange-500 to-orange-400 rounded-lg shadow-md text-white max-w-lg m-4 text-center leading-7">
                    <p>With a method that has been seamlessly integrated into all walks of life and has achieved hundreds of mind & body transformations,
                        the days of spending countless hours in the gym and hopping from one diet to another with very little to no results are OVER.</p>
                </div>
            </Card>
            <Card>
                <div className="p-6 bg-gradient-to-r from-orange-500 to-orange-400 rounded-lg shadow-md text-white max-w-lg m-4 text-center leading-7">
                    <p>It's so much more than just dieting and training, you will be creating a better life as you build a body all those around you will be in awe of,
                        you will earn more respect, build unwavering discipline and gain unbreakable confidence.</p>
                </div>
            </Card>
            <Card>
                <div className="p-6 bg-gradient-to-r from-orange-500 to-orange-400 rounded-lg shadow-md text-white max-w-lg m-4 text-center leading-7">
                    <p>The biggest  mistake you will make in life is thinking you have time, do not let another day go by without making a change,
                        start to unlock your full potential and really see what this world has to offer.</p>
                </div>
            </Card>
            <Card>
                <div className="p-6 bg-gradient-to-r from-orange-500 to-orange-400 rounded-lg shadow-md text-white max-w-lg m-4 text-center leading-7">
                    <p>If you're ready to build a better body, a stronger mind and start living the life you always wanted, submit the form below and I will contact you with all the details.</p>
                </div>
            </Card>
        </div>
    );
}

export default Cards;